// Dependencies
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router";
import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

// Redux dependencies
import { useSelector } from "react-redux";

//login
import PermissionDenied from "./components/auth/PermissionDenied";

//main navigation
import ContactUs from "./components/contactUs/ContactUs";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import Logout from "./components/auth/Logout";
import Notifications from "./Notifications";

import { PangeaSpinner } from "./components/SharedComponents";
import TasksRoutes from "./routes/TasksRoutes";
import TaskRoutes from "./routes/TaskRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import ReportsRoutes from "./routes/ReportsRoutes";

export function ScrollToTopOnMount() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.user.auth);

  if (auth.isAdmin === undefined || auth.isDataViewer === undefined) {
    return <PangeaSpinner />;
  }

  if (!auth.isAdmin && !auth.isDataViewer) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const Reader = React.lazy(() => import("./components/reader/Reader"));
const Library = React.lazy(() => import("./components/library/Library"));

export default function Router() {
  //Render
  return (
    <SentryRoutes>
      {/* Root */}
      <Route path="/" element={<Navigate to="/tasks" replace />} />

      <Route path="/logout" element={<Logout />} />
      <Route path="/permissionDenied" element={<PermissionDenied />} />

      {/* Tasks */}
      <Route
        path="/tasks/*"
        element={
          <Suspense fallback={<PangeaSpinner />}>
            <TasksRoutes />
          </Suspense>
        }
      />

      {/* Task */}
      <Route
        path="/task/*"
        element={
          <Suspense fallback={<PangeaSpinner />}>
            <TaskRoutes />
          </Suspense>
        }
      />

      {/* Library */}
      <Route
        path="/library"
        element={
          <Suspense fallback={<PangeaSpinner />}>
            <Library />
          </Suspense>
        }
      />

      {/* Reader */}
      <Route
        path="/reader"
        element={
          <Suspense fallback={<PangeaSpinner />}>
            <Reader grSettings={{ showSelfFeedback: true }} />{" "}
          </Suspense>
        }
      />

      {/* Contact Us */}
      <Route path="/contactUs" element={<ContactUs />} />

      {/* Notifications */}
      <Route path="/notifications" element={<Notifications />} />

      {/* User Profile */}
      <Route path="/profile" element={<ProfilePage />} />

      {/* Reports */}
      <Route
        path="/reports/*"
        element={
          <Suspense fallback={<PangeaSpinner />}>
            <ReportsRoutes />
          </Suspense>
        }
      />

      {/* Admin routes */}
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <Suspense fallback={<PangeaSpinner />}>
              <AdminRoutes />
            </Suspense>
          </ProtectedRoute>
        }
      />

      {/* QA */}
      <Route
        path="/test/*"
        element={
          <ProtectedRoute>
            <Suspense fallback={<PangeaSpinner />}>
              <SentryRoutes>
                <Route path="crash" element={<ErrorComponent />} />
              </SentryRoutes>
            </Suspense>
          </ProtectedRoute>
        }
      />
    </SentryRoutes>
  );
}

// Component for testing error boundaries
export function ErrorComponent() {
  console.log("Throwing error");
  throw new Error("Test error");
}
