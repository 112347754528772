import React from "react";
import { Routes, Route } from "react-router";
import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const Admin = React.lazy(() => import("../components/admin/Admin"));
const Playground = React.lazy(
  () => import("../components/admin/playground/Playground")
);
const AdminCourse = React.lazy(() => import("../components/admin/Course"));
const UserConversations = React.lazy(
  () => import("../components/admin/UserConversations")
);
const FailedQueuedTasks = React.lazy(
  () => import("../components/admin/monitoring/FailedQueuedTasks")
);
const ActivityReport = React.lazy(
  () => import("../components/admin/reports/activityReport/ActivityReport")
);
const CourseActivityReport = React.lazy(
  () =>
    import("../components/admin/reports/courseActivity/CourseActivityReport")
);
const InstitutionActivityReport = React.lazy(
  () =>
    import(
      "../components/admin/reports/institutionActivity/InstitutionActivityReport"
    )
);

export default function AdminRoutes() {
  return (
    <SentryRoutes>
      <Route index element={<Admin />} />
      <Route path="playground" element={<Playground />} />
      <Route path="course" element={<AdminCourse />} />
      <Route path="conversations" element={<UserConversations />} />
      <Route path="monitoring" element={<FailedQueuedTasks />} />
      <Route path="activityReport" element={<ActivityReport />} />
      <Route path="courseActivityReport" element={<CourseActivityReport />} />
      <Route
        path="institutionActivityReport"
        element={<InstitutionActivityReport />}
      />
    </SentryRoutes>
  );
}
