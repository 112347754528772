import React, { useState } from "react";
import { isEmulated, crossed } from "../../firebase";
import { useGetTheme, useImpersonate } from "../../hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Code as CodeIcon,
  Terminal as TerminalIcon
} from "@mui/icons-material";

const MOBILE_KEYWORDS = [
  "android",
  "webos",
  "iphone",
  "ipad",
  "ipod",
  "blackberry",
  "windows phone"
];

const DESKTOP_PLATFORMS = ["win32", "macintel", "linux x86_64", "linux i686"];

function AdminNotification() {
  const navigate = useNavigate();
  const { stopImpersonate } = useImpersonate();
  const theme = useGetTheme();
  const originalAuth = useSelector((state) => state.user.original_auth);

  const [expand, setExpand] = useState(true);
  const [showOwnershipDialog, setShowOwnershipDialog] = useState(false);
  const [takingOwnershipConfirmed, setTakingOwnershipConfirmed] =
    useState(false);

  const isImpersonation = originalAuth && originalAuth !== -1;

  const handleTakeOwnership = () => {
    localStorage.removeItem("impersonate.originalUser");
    window.location.reload();
  };

  const handleStopEmulating = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("emulated", false);
    navigate(url.pathname + url.search + url.hash);
    window.location.reload();
  };

  const getNotificationContent = () => {
    if (isImpersonation) {
      return expand ? (
        "Impersonation"
      ) : (
        <SupervisedUserCircleIcon color="inherit" />
      );
    }
    if (isEmulated) {
      return expand ? "Emulated" : <CodeIcon color="inherit" />;
    }
    if (crossed) {
      return expand ? (
        `Crossed with ${crossed.charAt(0).toUpperCase() + crossed.slice(1)} backend`
      ) : (
        <TerminalIcon color="inherit" />
      );
    }
    return null;
  };

  const isUnsupportedDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();

    const isMobile = MOBILE_KEYWORDS.some((keyword) =>
      userAgent.includes(keyword)
    );
    const isTablet =
      userAgent.includes("ipad") ||
      (userAgent.includes("android") && !userAgent.includes("mobile"));
    const isDesktop = DESKTOP_PLATFORMS.includes(platform);
    const isIpadPro =
      userAgent.includes("macintosh") && navigator.maxTouchPoints > 1;

    return isMobile || isTablet || isIpadPro || !isDesktop;
  };

  const renderOwnershipDialog = () => (
    <Dialog
      open={showOwnershipDialog}
      onClose={() => {
        setShowOwnershipDialog(false);
        setTakingOwnershipConfirmed(false);
      }}
      aria-labelledby="ownership-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="ownership-dialog-title">Delete confirmation</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to do this?</Typography>
        <FormGroup>
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={takingOwnershipConfirmed}
                onChange={(e) => setTakingOwnershipConfirmed(e.target.checked)}
              />
            }
            label="I'm sure"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowOwnershipDialog(false);
            setTakingOwnershipConfirmed(false);
          }}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setShowOwnershipDialog(false);
            setTakingOwnershipConfirmed(false);
            handleTakeOwnership();
          }}
          color="primary"
          disabled={!takingOwnershipConfirmed}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderActionButtons = () => (
    <Box sx={{ display: "flex", alignItems: expand ? "center" : "flex-end" }}>
      {isImpersonation && expand && (
        <>
          <Link
            variant="body2"
            color="inherit"
            underline="none"
            component="button"
            onClick={stopImpersonate}
            sx={{ marginRight: "16px" }}>
            Stop impersonation
          </Link>
          <Link
            variant="body2"
            color="inherit"
            underline="none"
            component="button"
            onClick={() => setShowOwnershipDialog(true)}
            sx={{ marginRight: "8px" }}>
            Take ownership
          </Link>
        </>
      )}
      {isEmulated && expand && (
        <Link
          variant="body2"
          color="inherit"
          underline="none"
          component="button"
          onClick={handleStopEmulating}
          sx={{ marginRight: "16px" }}>
          Stop emulating
        </Link>
      )}
      <IconButton
        onClick={() => setExpand(!expand)}
        size="small"
        color="inherit"
        aria-label={expand ? "Show less" : "Show more"}>
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
  );

  const renderNotificationAlert = (content) => (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        sx={{
          margin: "8px",
          position: "absolute",
          zIndex: "10000",
          width: expand ? "600px" : "100px",
          top: expand ? "0px" : "-16px"
        }}>
        <Alert
          severity="info"
          icon={expand}
          sx={{
            "& .MuiAlert-action": { paddingTop: "0" },
            "& .MuiAlert-message": { paddingBottom: expand ? "8px" : "0" },
            display: "flex",
            alignItems: expand ? "center" : "flex-end"
          }}
          action={renderActionButtons()}>
          {content}
        </Alert>
      </Box>
    </Box>
  );

  // If we have a notification condition, show that
  if (isEmulated || crossed || isImpersonation) {
    const content = getNotificationContent();
    return (
      <>
        {renderNotificationAlert(content)}
        {showOwnershipDialog && renderOwnershipDialog()}
      </>
    );
  }

  // If no notification conditions and device is supported, return null
  if (!isUnsupportedDevice()) {
    return null;
  }

  // Show unsupported device message
  return renderNotificationAlert(
    expand
      ? "Alethea is designed for desktop use. Kindly access it from a computer."
      : null
  );
}

export default AdminNotification;
