import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  dequeueFlashMessage,
  flashClosed,
  addUndoActions,
  removeUndoActions
} from "./redux/userSlice";
import { SnackbarContent, Snackbar, Button, Box } from "@mui/material";
import { Alert } from "@mui/material";
import {
  selectAlertsDuration,
  selectTextDirection
} from "./redux/firestoreSelectors";
import { useGetTheme } from "./hooks";

export default function FlashMessages() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme();
  const textDirection = useSelector((state) => selectTextDirection(state));
  const flashMessages = useSelector((state) => state.user.flashMessages);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  function parseMessage(message) {
    if (Array.isArray(message)) {
      return (
        <>
          {message.map((line) => (
            <Box key={line}>{line}</Box>
          ))}
        </>
      );
    } else {
      return message;
    }
  }

  const renderFlashMessage = (flashMessage, index) => {
    return (
      <Snackbar
        key={flashMessage.id}
        open={Boolean(flashMessages)}
        autoHideDuration={parseInt(alertsDuration)}
        onClose={() => {
          dispatch(dequeueFlashMessage(flashMessage.id));
          flashMessage.undoData &&
            dispatch(removeUndoActions(flashMessage.undoData));
          dispatch(flashClosed(true));
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: textDirection === "rtl" ? "left" : "right"
        }}
        sx={{
          position: "static",
          marginBottom:
            index !== flashMessages.length - 1 ? theme.spacing(2) : 0,
          display: "block",
          "& .MuiSnackbar-root": {
            position: "static"
          },
          "& .MuiPaper-root": {
            marginBottom: 0
          }
        }}
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: "onTouchStart"
        }}>
        {flashMessage.severity ? (
          <Alert severity={flashMessage.severity}>
            {parseMessage(flashMessage.message)}
          </Alert>
        ) : (
          <SnackbarContent
            message={flashMessage.message}
            sx={{ color: "snackbar.text" }}
            action={
              flashMessage.undoButton &&
              flashMessage.undoButton === true && (
                <Button
                  onClick={() => {
                    flashMessage.undoData &&
                      dispatch(addUndoActions(flashMessage.undoData));
                    dispatch(dequeueFlashMessage(flashMessage.id));
                  }}
                  sx={{
                    textTransform: "uppercase",
                    color: "snackbar.button"
                  }}
                  size="small">
                  {intl.formatMessage({
                    id: "undo",
                    defaultMessage: "undo"
                  })}
                </Button>
              )
            }
          />
        )}
      </Snackbar>
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        left: "auto",
        zIndex: theme.zIndex.drawer + 3
      }}>
      {flashMessages &&
        flashMessages.map((flashMessage, index) =>
          renderFlashMessage(flashMessage, index)
        )}
    </Box>
  );
}
