import { createSlice, current, createSelector } from "@reduxjs/toolkit";
import { determinePDFPagePosition } from "../components/reader/pdf/utils";
import { FEATURES, TEXT_TYPE, USER_PROFILE } from "../consts";
import { captureException } from "../utils/errorHandlers";
import { selectPageVisibility } from "./pdfSlice";
export const defaultProfile = {
  darkMode: false,
  fontSize: "6",
  location: "/",
  showCitationsTip: true,
  selectedCourseId: 0
};
import { v4 as uuid } from "uuid";

//Thunk
export function toggleDarkMode(darkMode) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();
    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { darkMode },
        { merge: true }
      );
    } catch (err) {
      captureException(err, `toggleDarkMode failed. user: ${userId}`);
    }
  };
}

export function acceptTermsOfUse() {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();
    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { acceptedTermsOfUse: true },
        { merge: true }
      );
    } catch (err) {
      captureException(err, `acceptTermsOfUse failed. user: ${userId}`);
    }
  };
}

export function updateAlertsDuration(duration) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();

    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { alertsDuration: duration },
        { merge: true }
      );
    } catch (err) {
      captureException(err, `updateAlertsDuration failed. user: ${userId}`);
    }
  };
}

export function updateTextLocation(params) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    const { text_id, position, type, lastPage } = params;
    let newPosition = position;
    if (type === TEXT_TYPE.PDF) {
      const { currentPage } = state.pdf;
      const currentPageVisibility = selectPageVisibility(state, currentPage);
      const previusPageVisibility = selectPageVisibility(
        state,
        currentPage - 1
      );
      const nextPageVisibility = selectPageVisibility(state, currentPage + 1);

      newPosition = determinePDFPagePosition(
        currentPageVisibility,
        previusPageVisibility,
        nextPageVisibility
      );
    }
    try {
      firestore.doc(`textLocations/${userId}/texts/${text_id}`).set({
        lastPage: lastPage,
        position: newPosition,
        type: type
      });
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update user's text location. user ${userId}`
      );
      return false;
    }
  };
}

//Thunk
export function updateAvatar(url) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();
    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { photoURL: url },
        { merge: true }
      );
    } catch (err) {
      captureException(err, `updateAvatar failed. user: ${userId}`);
    }
  };
}

export function changeLocale(locale) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();
    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { locale: locale.tag },
        { merge: true }
      );
    } catch (err) {
      captureException(
        err,
        `changeLocale failed. user: ${userId}, locale: ${locale}`
      );
    }
  };
}

export function changeSidebarWidth(width) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    const firestore = getFirestore();
    try {
      firestore.set(
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.CROSS_FEATURE}`,
        { sidebarWidth: width },
        { merge: true }
      );
    } catch (err) {
      captureException(
        err,
        `changeSidebarWidth failed. user: ${userId}, sidebarWidth: ${width}`
      );
    }
  };
}

const initialState = {
  userProfile: defaultProfile,
  userProfileSet: false,
  snackbarContent: {},
  openTasks: [],
  openTextTasks: [],
  fontSizeOptions: {
    5: {
      h1: {
        "font-size": "32px !important",
        "line-height": "40px !important"
      },
      h4: {
        "font-size": "18px !important",
        "line-height": "26px !important"
      },
      h2: {
        "font-size": "22px !important",
        "line-height": "30px !important",
        "margin-top": "36px",
        "margin-bottom": "20px"
      },
      h3: {
        "font-size": "22px !important",
        "line-height": "22px !important"
      },
      "h1.h.sigil_not_in_toc": {
        "font-size": "18px !important",
        "line-height": "30px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "12px !important",
          "line-height": "30px !important"
        },
      body: {
        "font-size": "20px !important",
        "line-height": "30px !important"
      },
      ".info": {
        "font-size": "14px !important",
        "line-height": "22px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "22px !important",
        "line-height": "22px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "12px !important",
        "line-height": "20px !important"
      }
    },
    6: {
      h1: {
        "font-size": "34px !important",
        "line-height": "42px !important"
      },
      h4: {
        "font-size": "20px !important",
        "line-height": "28px !important"
      },
      h2: {
        "font-size": "24px !important",
        "line-height": "32px !important",
        "margin-top": "40px",
        "margin-bottom": "24px"
      },
      h3: {
        "font-size": "24px !important",
        "line-height": "24px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "14px !important",
          "line-height": "32px !important"
        },
      body: {
        "font-size": "22px !important",
        "line-height": "32px !important"
      },
      ".info": {
        "font-size": "16px !important",
        "line-height": "24px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "24px !important",
        "line-height": "24px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "14px !important",
        "line-height": "22px !important"
      }
    },
    7: {
      h1: {
        "font-size": "36px !important",
        "line-height": "44px !important"
      },
      h4: {
        "font-size": "22px !important",
        "line-height": "30px !important"
      },
      h2: {
        "font-size": "26px !important",
        "line-height": "34px !important",
        "margin-top": "48px",
        "margin-bottom": "32px"
      },
      h3: {
        "font-size": "26px !important",
        "line-height": "26px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "16px !important",
          "line-height": "34px !important"
        },
      body: {
        "font-size": "24px !important",
        "line-height": "34px !important"
      },
      ".info": {
        "font-size": "18px !important",
        "line-height": "26px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "26px !important",
        "line-height": "26px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "16px !important",
        "line-height": "24px !important"
      }
    },
    8: {
      h1: {
        "font-size": "38px !important",
        "line-height": "46px !important"
      },
      h4: {
        "font-size": "24px !important",
        "line-height": "32px !important"
      },
      h2: {
        "font-size": "28px !important",
        "line-height": "36px !important",
        "margin-top": "40px",
        "margin-bottom": "40px"
      },
      h3: {
        "font-size": "28px !important",
        "line-height": "28px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "18px !important",
          "line-height": "36px !important"
        },
      body: {
        "font-size": "26px !important",
        "line-height": "36px !important"
      },
      ".info": {
        "font-size": "24px !important",
        "line-height": "28px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "228x !important",
        "line-height": "28px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "18x !important",
        "line-height": "26px !important"
      }
    }
  },
  //...loadState(),
  offline: false,
  loggedOut: false,
  auth: -1,
  original_auth: -1,
  flashMessages: [],
  // This is a flag that can trigger componnent level logic after flash message closes
  flashClosed: false,
  // This is a flag that can trigger componnent level logic after undo
  undoActions: [],
  tasksViewed: [],
  selectedCourseId: 0
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => {
      return { ...initialState, loggedOut: true };
    },
    setOriginalUser: (state, value) => {
      let auth = current(state.auth);
      if (state.original_auth === -1 || value.payload === -1) {
        state.original_auth = value.payload;

        //don't allow override
      }

      if (value.payload !== -1) {
        state.auth = {
          displayName: auth.uid,
          photoURL: "",
          uid: auth.uid,
          email: auth.uid
        };
      }
    },

    setAuth: (state, value) => {
      state.auth = value.payload;
    },
    setAdmin: (state, value) => {
      state.auth.isAdmin = value.payload;
    },
    setDataViewer: (state, value) => {
      state.auth.isDataViewer = value.payload;
    },
    setProfile: (state, value) => {
      state.userProfile = value.payload;
      state.userProfileSet = true;
    },
    setSnackbarContent: (state, value) => {
      state.snackbarContent = value.payload;
    },
    toggleOpenTask: (state, value) => {
      let currentTasks = current(state.openTasks);

      if (currentTasks.includes(value.payload)) {
        state.openTasks = currentTasks.filter((e) => e !== value.payload);
      } else state.openTasks = [...currentTasks, value.payload];
    },
    setOffline: (state, value) => {
      state.offline = value.payload;
    },
    toggleOpenTextTask: (state, value) => {
      let currentTasks = current(state.openTextTasks);
      if (currentTasks.includes(value.payload)) {
        state.openTextTasks = currentTasks.filter((e) => e !== value.payload);
      } else state.openTextTasks = [...currentTasks, value.payload];
    },
    enqueueFlashMessage: (state, value) => {
      const flashMessage = { ...value.payload, id: uuid() };
      state.flashMessages.push(flashMessage);
    },
    dequeueFlashMessage: (state, value) => {
      const index = state.flashMessages.findIndex(
        (m) => m.id === value.payload
      );
      state.flashMessages.splice(index, 1);
    },
    clearFlashMessages: (state, value) => {
      state.flashMessages = [];
    },
    flashClosed: (state, value) => {
      state.flashClosed = value.payload;
    },
    addUndoActions: (state, value) => {
      state.undoActions.push(value.payload);
    },
    removeUndoActions: (state, value) => {
      const undoData = value.payload;
      const index = state.undoActions.findIndex(
        (u) => u.component === undoData.component && u.id === undoData.id
      );
      state.undoActions.splice(index, 1);
    },
    undo: {
      //
    },
    setTasksViewed: (state, value) => {
      state.tasksViewed = value.payload || [];
    }
  }
});

export const {
  setProfile,
  setAuth,
  setAdmin,
  setDataViewer,
  setOriginalUser,
  toggleOpenTask,
  toggleOpenTextTask,
  setOffline,
  logout,
  setSnackbarContent,
  enqueueFlashMessage,
  dequeueFlashMessage,
  clearFlashMessages,
  flashClosed,
  addUndoActions,
  removeUndoActions,
  undo,
  setTasksViewed
} = userSlice.actions;

export default userSlice.reducer;

// Selectors
export const selectIsImpersonation = (state) => {
  if (state.user?.original_auth) {
    return state.user?.original_auth !== -1;
  } else return false;
};

export const taskViewed = createSelector(
  [
    (state) => state.user.tasksViewed,
    (state) => state.tasks.activeGrTaskId,
    (state) => state.tasks.selectedTaskId,
    (state) => state.router.location.pathname
  ],
  (tasksViewed, activeGrTaskId, selectedTaskId, pathname) => {
    let taskId;
    if (pathname.includes("task")) {
      // this is a standard task
      taskId = selectedTaskId;
    } else {
      // this is a gr task
      taskId = activeGrTaskId;
    }
    return tasksViewed.find((id) => id === taskId) !== undefined;
  }
);
