import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

// Improved version validation and comparison
const parseVersion = (version) => {
  if (typeof version !== "string") return null;
  const match = version.match(/^\d+(\.\d+)*$/);
  if (!match) return null;

  // Normalize version to ensure consistent comparison
  return version.split(".").map(Number);
};

const compareVersions = (v1, v2) => {
  const parsed1 = parseVersion(v1);
  const parsed2 = parseVersion(v2);

  // If either version is invalid, consider them equal to prevent unnecessary updates
  if (!parsed1 || !parsed2) return false;

  // Pad shorter version with zeros
  const maxLength = Math.max(parsed1.length, parsed2.length);
  const normalized1 = [
    ...parsed1,
    ...Array(maxLength - parsed1.length).fill(0)
  ];
  const normalized2 = [
    ...parsed2,
    ...Array(maxLength - parsed2.length).fill(0)
  ];

  // Compare version numbers
  for (let i = 0; i < maxLength; i++) {
    if (normalized1[i] > normalized2[i]) return true;
    if (normalized1[i] < normalized2[i]) return true;
  }
  return false;
};

const parseRemoteConfig = (raw) => {
  try {
    const parsed = typeof raw === "string" ? JSON.parse(raw) : raw;
    return typeof parsed === "object" && parsed !== null ? parsed : {};
  } catch (error) {
    console.error("Failed to parse remoteConfig:", error);
    return {};
  }
};

export default function VersionUpdateDialog() {
  const firestoreAppVersion = useSelector(
    (state) => state.firestore.data?.defaultConfig?.VERSION
  );

  const [remoteConfigRaw, setRemoteConfigRaw] = useLocalStorage(
    "conf.lastRemoteConfig",
    JSON.stringify({ version: firestoreAppVersion }) // Initialize with current version
  );

  const shouldShow = useMemo(() => {
    if (!firestoreAppVersion) return false;

    const remoteConfigParsed = parseRemoteConfig(remoteConfigRaw);

    // Only show if there's a valid version difference
    return compareVersions(firestoreAppVersion, remoteConfigParsed.version);
  }, [remoteConfigRaw, firestoreAppVersion]);

  const updateRemoteConfig = () => {
    const remoteConfigParsed = parseRemoteConfig(remoteConfigRaw);
    const payload = {
      ...remoteConfigParsed,
      version: firestoreAppVersion
    };
    setRemoteConfigRaw(JSON.stringify(payload));
  };

  return (
    <Dialog open={shouldShow} onClose={updateRemoteConfig}>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="upgrade.msg"
            defaultMessage="A new Alethea version is available, click 'Reload' to update"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label="Reload"
          sx={{
            backgroundColor: "#168fee",
            color: "#ffffff",
            textTransform: "none",
            "&:hover": {
              background: "#1172be"
            }
          }}
          onClick={() => {
            updateRemoteConfig();
            window.location.reload(true);
          }}>
          <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
