import React from "react";
import { Routes, Route } from "react-router";

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const Task = React.lazy(() => import("../components/Tasks/Task"));
const TaskStatsView = React.lazy(
  () => import("../components/Tasks/Stats/TaskStatsView")
);

export default function TaskRoutes() {
  return (
    <SentryRoutes>
      <Route index element={<Task />} />
      <Route path="stats" element={<TaskStatsView />} />
    </SentryRoutes>
  );
}
