import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useFeatureFlags } from "./hooks/useFeatureFlags";
import { FEATURE_FLAGS } from "./consts";
import { useSelector } from "react-redux";

export const UserApp = () => {
  // Hooks
  const userId = useSelector((state) => state.firebase.auth.uid);
  const userProfile = useSelector((state) => state.user.userProfile);
  const isPendoEnabled = useFeatureFlags(FEATURE_FLAGS.ENABLE_PENDO);

  // Ephemeral state
  const [pendoInitialized, setPendoInitialized] = useState(false);

  // Constants
  const isLocalhost = window.location.hostname === "localhost";

  useEffect(() => {
    if (
      isPendoEnabled &&
      !pendoInitialized &&
      userId &&
      userProfile.selectedCourse &&
      !isLocalhost
    ) {
      window.pendo.initialize({
        visitor: {
          id: userId
        },
        account: {
          id: userProfile.selectedCourse.institution_id
        }
      });
      setPendoInitialized(true);
    }
  }, [userId, userProfile, pendoInitialized, isPendoEnabled, isLocalhost]);

  return <Layout />;
};
