import { configureStore, combineReducers } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { firebaseReducer } from "react-redux-firebase";
import { createEpicMiddleware } from "redux-observable";
import { fetchAsObservable } from "./epics/utils";
import { rootEpic } from "./epics/rootEpic";
import {
  getFirebase,
  actionTypes as rrfActionTypes
} from "react-redux-firebase";
import {
  constants as rfConstants,
  getFirestore,
  firestoreReducer
} from "redux-firestore";

// Import the new router reducer and middleware
import routerReducer from "./routerSlice";
import { routerMiddleware } from "./routerMiddleware";

// Other reducers
import readerActionsReducer from "./readerActionsSlice";
import highlightReducer from "./highlightSlice";
import grReducer from "./grSlice";
import linkSlice from "./LinkSlice";
import pdfSlice from "./pdfSlice";
import taskSlice from "./taskSlice";
import commentReducer from "./commentsSlice";
import textReducer from "./textsSlice";
import userReducer from "./userSlice";
import coursesSlice from "./coursesSlice";
import tasksSlice from "./tasksSlice";
import interactionsSlice from "./interactionsSlice";
import realtimeInteractionsSlice from "./realtimeInteractionsSlice";
import snackbarSlice from "./snackbarSlice";
import firebaseMiddleware from "./firebaseMiddleware";
import layoutSlice from "./layoutSlice";
import readerSlice from "./readerSlice";
import chatSlice from "./chatSlice";
import fabSlice from "./fabSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      user: state.user?.auth?.uid || "unknown"
    };
    return transformedState;
  }
});

const extraArgument = {
  getFirebase,
  getFirestore
};

const epicMiddleware = createEpicMiddleware({
  dependencies: { fetchAsObservable }
});

const getMiddleware = (getDefaultMiddleware) => [
  firebaseMiddleware,
  epicMiddleware,
  routerMiddleware,
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        // Firebase and react-redux-firebase action types
        ...Object.keys(rfConstants.actionTypes).map(
          (type) => `${rfConstants.actionsPrefix}/${type}`
        ),
        ...Object.keys(rrfActionTypes).map(
          (type) => `@@reactReduxFirebase/${type}`
        ),
        // Add router actions to ignored serialization checks if needed
        "router/NAVIGATE",
        "router/REPLACE"
      ],
      ignoredPaths: ["firebase", "firestore"]
    },
    thunk: {
      extraArgument: { getFirebase, getFirestore }
    }
  })
];

const combinedReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: routerReducer,
  layout: layoutSlice,
  readerActions: readerActionsReducer,
  highlighter: highlightReducer,
  gr: grReducer,
  texts: textReducer,
  task: taskSlice,
  user: userReducer,
  link: linkSlice,
  comments: commentReducer,
  courses: coursesSlice,
  tasks: tasksSlice,
  interactions: interactionsSlice,
  realtimeInteractions: realtimeInteractionsSlice,
  snackbar: snackbarSlice,
  pdf: pdfSlice,
  reader: readerSlice,
  chat: chatSlice,
  fab: fabSlice
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  reducer: rootReducer,
  middleware: getMiddleware
});

epicMiddleware.run(rootEpic);

export default store;
