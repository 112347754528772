import { createSlice, current, createSelector } from "@reduxjs/toolkit";
import { REDUX_ACTIONS } from "../consts";
//Thunk
// TODO: 11/09/22 this thunk is a bandaid, it should be removed once the config refactor is done

const initialState = {
  grHighlights: [],
  answers: [],
  selectedQuestionIndex: 0,
  selectedQuestionDisplayIndex: 0,
  selectedTaskId: 0,
  updatedTaskId: 0,
  submittedStudentTask: 0,
  submittedStudentReflection: 0,
  submittedStudentResponse: 0,
  submittedStudentReply: 0,
  sentFeedbackTask: 0,
  sentFeedbackTextTask: 0,
  clientRectangle: {},
  clickedMatch: false,
  selectedText: {},
  feedbackTask: {},
  sendFeedback: false,
  validateFeedback: false,
  selectedHighlight: {},
  task_type: "",
  status: "",
  step: "highlight",
  highlightViewOn: false,
  updatedAt: 0
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase("gotAnswers", (state, value) => {
        const updateTime = new Date(value.payload.updatedAt).getTime();
        if (updateTime > state.updatedAt) {
          state.updatedTaskId = value.payload.taskId;
          state.step = value.payload.step || "highlight";
          state.highlightViewOn = value.payload.highlightViewOn || false;
          state.status = value.payload.status;
          state.selectedQuestionIndex = value.payload.selectedQuestion;
          state.updatedAt = updateTime;
        }
      })
      .addCase("user/logout", () => initialState)
      .addCase(REDUX_ACTIONS.USER_NAVIGATED, () => initialState);
  },
  reducers: {
    setSentFeedbackTask: (state, value) => {
      state.sentFeedbackTask = value.payload;
      state.sendFeedback = false;
    },
    setSentFeedbackTextTask: (state, value) => {
      state.sentFeedbackTextTask = value.payload;
      state.sendFeedback = false;
    },
    setSubmittedStudentTask: (state, value) => {
      state.submittedStudentTask = value.payload;
    },
    setSubmittedStudentReflection: (state, value) => {
      state.submittedStudentReflection = value.payload;
    },
    setSubmittedStudentReview: (state, value) => {
      state.submittedStudentResponse = value.payload;
    },
    setSubmittedStudentReply: (state, value) => {
      state.submittedStudentReply = value.payload;
    },
    setSelectedTask: (state, value) => {
      state.selectedTaskId = value.payload;
      state.selectedQuestionIndex = 0;
    },
    setQuestionIndex: (state, value) => {
      state.selectedQuestionIndex = value.payload;
    },
    setFeedbackTask: (state, value) => {
      state.feedbackTask = value.payload;
    },

    setSelectedText: (state, value) => {
      state.selectedText = value.payload;
    },
    setClickedMatch: (state, value) => {
      state.clickedMatch = value.payload;
    },
    sendFeedback: (state, value) => {
      state.sendFeedback = value.payload;
    },
    validateFeedback: (state, value) => {
      state.validateFeedback = value.payload;
    },
    setSelectedQuestionDisplayIndex: (state, value) => {
      state.selectedQuestionDisplayIndex = value.payload;
    },
    // Currently only used for impersonating, should be moved out of here!
    setStep: (state, value) => {
      state.step = value.payload;
    },
    setHighlightViewOn: (state, value) => {
      state.highlightViewOn = value.payload;
    }
  }
});

export const {
  setSubmittedStudentTask,
  setSubmittedStudentReflection,
  setSubmittedStudentReview,
  setSubmittedStudentReply,
  setSentFeedbackTask,
  setSentFeedbackTextTask,
  setSelectedTask,
  setClickedMatch,
  setSelectedText,
  setQuestionIndex,
  setSelectedQuestionDisplayIndex,
  sendFeedback,
  validateFeedback,
  setFeedbackTask,
  setStep,
  setHighlightViewOn
} = taskSlice.actions;

export default taskSlice.reducer;
