import { firebaseApp, firestore, firebaseFunctions, auth } from "../firebase";
import { createAction } from "@reduxjs/toolkit";
import { captureException } from "../utils/errorHandlers";
import { REDUX_ACTIONS } from "../consts";

export const getNextId = (collection) => {
  if (!collection || collection.length === 0) return 1;
  return (
    Math.max.apply(
      Math,
      collection.map((t) => {
        return t.id && Number.isInteger(t.id) ? t.id : 0;
      })
    ) + 1
  );
};

const saveTaskState = firebaseFunctions.httpsCallable("courses-setStatus");

const gotHighlights = createAction("gotHighlights");
const gotComments = createAction("gotComments");
const gotPublicComments = createAction("gotPublicComments");
const gotAnswers = createAction("gotAnswers");
// This is a dummy action that only logs user navigation actions
export const userNavigated = createAction(REDUX_ACTIONS.USER_NAVIGATED);

export const updateHighlights = createAction("updateHighlights");
export const updatePrivateComments = createAction("updatePrivateComments");
export const updatePublicComments = createAction("updatePublicComments");
export const addPublicComments = createAction("addPublicComments");
export const deletePublicComments = createAction("deletePublicComments");
export const editReplyPublicComments = createAction("editReplyPublicComments");
export const deleteReplyPublicComments = createAction(
  "deleteReplyPublicComments"
);
export const editPublicComments = createAction("editPublicComments");
export const updateSq3r = createAction("updateSq3r");
export const addHighlightsToGrTask = createAction("addHighlightsToGrTask");
export const updateTask = createAction("updateTask");
const gotSq3r = createAction("gotSq3r");

export const markNotificationAsRead = createAction("markNotificationAsRead");
export const resetNotificationBedge = createAction("resetNotificationBedge");

const gotActiveTask = createAction("gotActiveTask");
export const setActiveTaskPerText = createAction("setActiveTaskPerText");
export const removeActiveTaskPerText = createAction("removeActiveTaskPerText");
//firestore.doc("/texts/14").set({"questions":[{"question":"מה הסוגיה המרכזית בטקסט?","text":"מה הסוגיה המרכזית בטקסט?","id":1},{"text":"מה עמדת המחבר בנוגע לסוגיה זאת?","question":"מה עמדת המחבר בנוגע לסוגיה זאת?","id":2},{"text":"מהן הטענות המרכזיות בטקסט?","question":"מהן הטענות המרכזיות בטקסט?","id":3},{"text":"כיצד מדגימ/ה המחבר/ת את הטענות?","id":4,"question":"כיצד מדגימ/ה המחבר/ת את הטענות?"},{"id":5,"text":"מהם המושגים או הדמויות המרכזיים בטקסט?","question":"מהם המושגים או הדמויות המרכזיים בטקסט?"}]});
const listenerUnsubscribeList = [];
export function fetchTask(storeAPI, taskId) {
  // if this is already the selected task, don't do anything
  if (taskId > 0 && taskId !== storeAPI.getState().task.updatedTaskId) {
    let docId =
      "tasks/" + firebaseApp.auth().currentUser.uid + "/task/" + taskId;
    const unsubscribe = firestore.doc(docId).onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          //storeAPI.dispatch(gotAnswers({ ...snapshot.data(), taskId }));
        } else {
          firestore
            .doc(docId)
            .set({ answers: [], selectedQuestion: 0, status: "Pending" });
        }
      },
      (error) => {
        captureException(error, `Failed to fetch tasks from firebase`);
      }
    );
  }
}

export function fetchFirebase(storeAPI, text_id, selectedText = null) {
  if (firebaseApp.auth().currentUser) {
    listenerUnsubscribeList.forEach((a) => a && a());
    listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);

    const unsubscribePrivateComments = firestore
      .doc(
        "comments/" + firebaseApp.auth().currentUser.uid + "/texts/" + text_id
      )
      .onSnapshot(
        (snapshot) => {
          let coll = snapshot.exists ? snapshot.data().comments : [];
          storeAPI.dispatch(gotComments(coll));
        },
        (error) => {
          captureException(error, `Failed to fetch comments from firebase`);
        }
        //   dispatch(fetchFirebaseError(error)) },
      );
    listenerUnsubscribeList.push(unsubscribePrivateComments);

    if (text_id > 0) {
      // dispatch(fetchFirebasePending());
      const unsubscribeHighlights = firestore
        .doc(
          "highlights/" +
            firebaseApp.auth().currentUser.uid +
            "/texts/" +
            text_id
        )
        .onSnapshot(
          (snapshot) => {
            let coll = snapshot.exists ? snapshot.data().highlights : [];
            storeAPI.dispatch(gotHighlights({ coll: coll, text_id: text_id }));
          },
          (error) => {
            captureException(error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeHighlights);

      const unsubscribeActiveTask = firestore
        .doc(
          "tasks/" +
            firebaseApp.auth().currentUser.uid +
            "/activeTaskPerText/" +
            text_id
        )
        .onSnapshot(
          (snapshot) => {
            let activeTask = snapshot.exists
              ? snapshot.data().activeTask
              : null;
            storeAPI.dispatch(gotActiveTask({ activeTask }));
          },
          (error) => {
            captureException(
              error,
              `Failed to fetch active task from firebase`
            );
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeActiveTask);

      const unsubscribePrivateComments = firestore
        .doc(
          "comments/" + firebaseApp.auth().currentUser.uid + "/texts/" + text_id
        )
        .onSnapshot(
          (snapshot) => {
            // let coll = snapshot.exists ? snapshot.data().comments : [];
            // storeAPI.dispatch(gotComments(coll));
          },
          (error) => {}
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribePrivateComments);

      const unsubscribePublicComments = firestore
        .collection("publicComments/" + text_id + "/comments2/")
        .onSnapshot((querySnapshot) => {
          const tempDoc = querySnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });

          storeAPI.dispatch(gotPublicComments(tempDoc));
        });

      listenerUnsubscribeList.push(unsubscribePublicComments);

      // This is a band-aid for a larger issue: There are two compeating data structures, task.questions in pgsql returns an object {question: []}, while grTasks returns the array []. if possible, this needs to be fixed in the psql db.
      let defaultQuestions = [];

      if (selectedText && Array.isArray(selectedText)) {
        defaultQuestions = selectedText;
      } else if (selectedText && typeof selectedText === "object") {
        defaultQuestions = selectedText.questions;
      } else if (
        storeAPI.getState().texts.selectedText &&
        storeAPI.getState().texts.selectedText?.questions
      ) {
        defaultQuestions = storeAPI.getState().texts.selectedText?.questions;
      }

      // const textObj = selectedText;
      //   ? selectedText
      //   : storeAPI.getState().texts.selectedText;
      let userId = firebaseApp.auth().currentUser.uid;
      //'xIZ2HmYhONMaeKTYhdDHGZzRS522';

      const unsubscribeSq3r = firestore
        //firebaseApp.auth().currentUser.uid
        .doc("sq3r/" + userId + "/texts/" + text_id)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              storeAPI.dispatch(
                gotSq3r({ ...snapshot.data(), text_id: text_id })
              );
            } else {
              const emptyGEdoc = {
                updatedAt: new Date().toISOString(),
                highlights: [],
                // questions: defaultQuestions || [],
                questions: [],
                status: "Active",
                grMode: "light",
                grStage: 0,
                grQuestionId: false,
                grShowAnswers: false,
                grShowHighlights: false
              };

              firestore
                .doc(
                  `sq3r/${firebaseApp.auth().currentUser.uid}/texts/${text_id}`
                )
                .set(emptyGEdoc);
            }
          },
          (error) => {
            captureException(error, `Failed to fetch SQ3R from firebase`);
          }
        );
      listenerUnsubscribeList.push(unsubscribeSq3r);
    }
  }
  auth.onAuthStateChanged(function (user) {
    if (!user) {
      listenerUnsubscribeList.forEach((a) => a && a());
      listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);
    }
  });
}

function handlePublicCommentsActions(storeAPI, firestore, action) {
  if (action.type === "addPublicComments") {
    firestore
      .collection("publicComments/" + action.payload.text_id + "/comments2")
      .add(action.payload.comment);
    //set userActions
  }
  if (action.type === "deletePublicComments") {
    firestore
      .collection("publicComments/" + action.payload.text_id + "/comments2")
      .doc(action.payload.comment.id)
      .delete();
    //set userActions
  }
  if (action.type === "editPublicComments") {
    return firestore.runTransaction((transaction) => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.text_id + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then((sfDoc) => {
        if (sfDoc.exists) {
          transaction.update(docRef, {
            content: action.payload.content,
            title: action.payload.title ? action.payload.title : "",
            updatedAt: new Date().toISOString()
          });
          //set userActions
        }
      });
    });
  }

  if (action.type === "editReplyPublicComments") {
    return firestore.runTransaction((transaction) => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.text_id + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then((sfDoc) => {
        if (sfDoc.exists) {
          let replies =
            sfDoc.data().replies && sfDoc.data().replies.length
              ? sfDoc.data().replies
              : [];
          // Add one person to the city population.
          // Note: this could be done without a transaction
          //       by updating the population using FieldValue.increment()
          let newReplies = replies.map((r) => {
            if (r.id === action.payload.replyId) {
              return {
                ...r,
                text: action.payload.text,
                updatedAt: new Date().toISOString()
              };
            } else return r;
          });

          transaction.update(docRef, { replies: newReplies });
          //set userActions
        }
      });
    });
  }

  if (action.type === "deleteReplyPublicComments") {
    return firestore.runTransaction((transaction) => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.text_id + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then((sfDoc) => {
        if (sfDoc.exists) {
          var replies = sfDoc.data().replies.filter((rep) => {
            return rep.id !== action.payload.replyId;
          });

          transaction.update(docRef, { replies: replies });
          //set userActions
        }
      });
    });
  }
}

const removeEmpty = (obj) => {
  let newObj = {};
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
      else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
  }
  return newObj;
};

function firebaseMiddleware(storeAPI) {
  return function wrapDispatch(next) {
    return async function handleAction(action) {
      if (!action) return;

      if (action.type === "user/setAuth") {
        //unsubscribe from all collections
        fetchFirebase(storeAPI, 0);
        // fetchTask(storeAPI, 0);
      }

      if (action.type === "texts/setSelectedTextId") {
        fetchFirebase(storeAPI, action.payload);
        //set userActions
      }
      if (action.type === "texts/setPangeaText") {
        fetchFirebase(storeAPI, action.payload.id, action.payload);
      }
      if (action.type === "tasks/setSelectedTaskId") {
        fetchTask(storeAPI, action.payload);
      }
      if (
        storeAPI.getState().user &&
        storeAPI.getState().user.original_auth === -1
      ) {
        if (action.type === "updateHighlights") {
          firestore
            .doc(
              "highlights/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                action.payload.text_id
            )
            .set({
              highlights: action.payload.highlights,
              updatedAt: new Date().toISOString()
            });
        }
        if (action.type === "updateSq3r") {
          const reduxState = storeAPI.getState();
          const currentUser = firebaseApp.auth().currentUser;
          let status =
            "status" in action.payload
              ? action.payload.status
              : storeAPI.getState().gr.status;
          firestore
            .doc(
              `sq3r/${currentUser.uid}/texts/${
                action.payload.text_id || reduxState.texts.selectedTextId
              }`
            )
            .update({
              updatedAt: new Date().toISOString(),
              highlights:
                action.payload.highlights ||
                reduxState.interactions.highlights ||
                [],
              questions:
                action.payload.questions ||
                reduxState.interactions.questions ||
                [],
              status: status
            })

            .catch((err) => {
              captureException(err);
            });
        }
        if (action.type === "user/setProfile") {
          let uid = firebaseApp.auth()?.currentUser?.uid;
          if (uid) {
            let docId = "users/" + firebaseApp.auth()?.currentUser?.uid;
            firestore.doc(docId).set(action.payload);
          }
        }
        if (action.type === "updateTask") {
          const reduxState = storeAPI.getState();
          const selectedTaskId = reduxState.tasks.selectedTaskId;
          const currentUser = firebaseApp.auth().currentUser;
          const docId = `tasks/${currentUser.uid}/task/${selectedTaskId}`;
          const doc = await firestore.doc(docId).get();
          const {
            answers = reduxState.task.answers,
            step = reduxState.task.step,
            selectedQuestion = reduxState.task.selectedQuestionIndex,
            highlightViewOn = reduxState.task.highlightViewOn
          } = action.payload;

          const updateObj = {
            status: "Active",
            step,
            selectedQuestion,
            highlightViewOn,
            updatedAt: new Date().toISOString()
          };
          firestore.doc(docId).update(updateObj);

          saveTaskState({ task: action.payload.taskId });
        }
        if (action.type === "updatePrivateComments") {
          firestore
            .doc(
              "comments/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                action.payload.text_id
            )
            .set({
              updatedAt: new Date().toISOString(),
              comments: action.payload.comments
            });
        }
        if (action.type === "setActiveTaskPerText") {
          const userId = firebaseApp.auth().currentUser?.uid;
          const text_id = action.payload.text_id;
          const taskUrl = action.payload.taskUrl;

          firestore.doc(`tasks/${userId}/activeTaskPerText/${text_id}`).set({
            activeTask: taskUrl
          });
        }
        if (action.type === "removeActiveTaskPerText") {
          const userId = firebaseApp.auth().currentUser?.uid;
          const text_id = action.payload.text_id;
          firestore.doc(`tasks/${userId}/activeTaskPerText/${text_id}`).set({
            activeTask: null
          });
        }

        handlePublicCommentsActions(storeAPI, firestore, action);
      }
      // Do anything here: pass the action onwards with next(action),
      // or restart the pipeline with storeAPI.dispatch(action)
      // Can also use storeAPI.getState() here

      try {
        return next(action);
      } catch (err) {
        // Trying to investigate -
        // https://pangea-ai.sentry.io/issues/4806353491/events/5ada00396c404aa6ac6389eb9c2cc04d/?project=6487416&referrer=replay-errors

        captureException(
          err,
          `Couldn't dispatch action ${JSON.stringify(action)}`
        );
        return next();
      }
    };
  };
}

export default firebaseMiddleware;
