// dependancies
import { httpCallables } from "../../../../firebase";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../../utils/errorHandlers";

// Redux
import { addSnackbarItem } from "../../../snackbarSlice";
import store from "../../../store";
import { removeActiveTaskPerText } from "../../../firebaseMiddleware";
import {
  fetchStudentCourseTasks,
  submitStandardTask
} from "../../../tasksSlice";

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { tap, map, catchError, exhaustMap } from "rxjs/operators";
import { navigationService } from "../../../../utils/navigation";

const CALLBACK = httpCallables.taskFunctions;
const ACTION = "submitStandardTask";
const ERROR_MSG = `SUBMIT_STANDARD_TASK`;

export const submitStandardTaskEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    // queues an offline task for submitting a task
    ofType(submitStandardTask.type),
    map((action) => action.payload),
    exhaustMap((args) =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        // open a snackbar to alert the user that the task was submitted
        tap(({ submission, offline_task_id }) =>
          alertUser(submission.id, offline_task_id)
        ),
        tap(({ submission }) =>
          removeActiveTask(submission.task_id, submission.user_id)
        ),
        tap(({ submission }) => {
          navigationService.navigate(
            `/tasks?course_id=${submission.course_id}`
          );
        }),
        map(({ submission }) => ({
          type: fetchStudentCourseTasks.type,
          payload: submission.course_id
        })),
        catchError((error) => {
          // TODO: handle error
          captureException(error, "Error in submitStandardTaskEpic");
          return EMPTY;
        })
      )
    )
  );
};

function alertUser(submission_id, offline_task_id) {
  store.dispatch({
    type: addSnackbarItem.type,
    payload: {
      offline_task_id,
      submission_id,
      actions: [
        {
          intlId: "undo",
          intlDefaultMsg: "undo",
          callBack: "undoStandardTaskSubmission"
        }
      ],
      intlId: "tasks.submitting",
      intlDefaultMessage: "Submitting task",
      id: uuid()
    }
  });
}

function removeActiveTask(task_id, user_id) {
  store.dispatch({
    type: removeActiveTaskPerText.type,
    payload: {
      task_id,
      user_id
    }
  });
}
