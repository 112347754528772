import React from "react";
import { Routes, Route } from "react-router";
import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const StudentAnalytics = React.lazy(
  () =>
    import("../components/SharedComponents/studentAnalytics/StudentAnalytics")
);
const CourseExternalReport = React.lazy(
  () => import("../components/SharedComponents/reports/CourseExternalReport")
);

export default function ReportsRoutes() {
  return (
    <SentryRoutes>
      <Route path="student" element={<StudentAnalytics />} />
      <Route path="courseExternal" element={<CourseExternalReport />} />
    </SentryRoutes>
  );
}
